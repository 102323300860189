import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import { APPOINTMENT_NAMESPACE } from "@/_store/appointment";
import { IAppointment, IConfigAppointmentModule } from "@/_store/types";
import { deepClone } from "fast-json-patch";
import PropertyCardComponent from "@/components/property-card/PropertyCard.vue";
import ContactAppointmentMail from "./MailTemplate/ContactAppointmentMail.vue";
import moment from "moment";
import {
  sendMessage,
  getAddressProperty,
  getRegionType,
  getBoolean,
} from "@/_utils";
import { IProperty } from "@/model/property.model";
import { LANGUAGE_CONST } from "@/_common";
import { AxiosResponse } from "axios";

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component({
  components: {
    PropertyCardComponent,
    ContactAppointmentMail,
  },
})
export default class ContactAppointment extends Vue {
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;

  @Action("getProperty", { namespace: APPOINTMENT_NAMESPACE })
  private getPropertyAction!: (criteria: {
    objectId: string;
    key: string;
  }) => Promise<any>;

  @Action("sendContactAppointment", { namespace: APPOINTMENT_NAMESPACE })
  private sendContactAppointment!: (criteria: {
    objectId: string;
    key: string;
    data: any;
    email: string;
    noEmail: boolean;
  }) => Promise<void>;

  @Action("getAppointmentByObjectId", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentByObjectId!: (criteria: {
    objectId: string;
    key: string;
    IsProject: boolean;
  }) => AxiosResponse<{ GetAppointmentObjectJsonResult: IAppointment[] }>;

  @Action("checkAppointmentExists", { namespace: APPOINTMENT_NAMESPACE })
  private checkAppointmentExists!: (id: number) => Promise<boolean>;

  @Action("checkIfUserRegistered", { namespace: APPOINTMENT_NAMESPACE })
  private checkIfUserRegistered!: (criteria: {
    email: string;
  }) => Promise<boolean>;

  @Getter("getAppointments", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointments!: IAppointment[];

  @Getter("getAppointmentByID", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentByID!: (id: string) => IAppointment;

  @Getter("getAppointmentClicked", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentClicked!: number;

  @Getter("getProperty", { namespace: APPOINTMENT_NAMESPACE })
  private getProperty!: IProperty;

  @Getter("getStatusSendFormLoading", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusSendFormLoading!: boolean;

  @Getter("getStatusSendForm", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusSendForm!: string;

  get isProject() {
    return getBoolean(this.getConfig.isProject);
  }

  get buttonBackColor() {
    return "#" + this.getConfig.buttonBackColor || "";
  }

  get buttonFontColor() {
    return "#" + this.getConfig.buttonFontColor || "";
  }

  get font() {
    return this.getConfig.font || "";
  }

  get propertyPrice() {
    let price = "";
    if (this.getProperty && this.getProperty.Price) {
      price = this.getProperty.Price.toString();
      price = this.getProperty.ChargesRenter
        ? `<b>${this.getProperty.Price} €</b> + <b>${
            this.getProperty.ChargesRenter
          } €</b> ${this.$t("CHARGES")}`
        : `${price} ‎€`;
      return price;
    }
    return "";
  }

  get propertyPicture() {
    if (this.getProperty) {
      if (this.getProperty.LargePicture) {
        return this.getProperty.LargePicture;
      }
      if (this.getProperty.LargePictureItem) {
        return this.getProperty.LargePictureItem.Url;
      }
      if (this.getProperty.MediumPictureItem) {
        return this.getProperty.MediumPictureItem.Url;
      }
      if (this.getProperty.SmallPictureItem) {
        return this.getProperty.SmallPictureItem.Url;
      }
    }
    return "";
  }

  get propertyAddress() {
    if (this.getProperty) {
      return getAddressProperty(this.getProperty);
    }
    return "";
  }

  get property() {
    if (this.getProperty) {
      return this.getProperty;
    }
    return {};
  }

  get coPropertyGoal() {
    if (this.getProperty) {
      return this.getProperty.Goal;
    }
    return -1;
  }

  private secretKey: string = "";
  private objectId: string = "";
  private isMobile: string = "";
  private appointmentId: string = "";
  private subject_mail = "";
  private email = "";
  private logo = "";
  private attach_mail = false;
  private showTermOfServices = true;

  private form = {
    AddressTitle: "",
    FirstName: "",
    Name: "",
    PhoneNumber: "",
    Email: "",
    // Content: '',
    IsReceivedEmail: false,
    PropertyLink: "",
    roofs: [],
  };

  private roofsCheck = {
    EnoughMoney: true,
    MaxPerson: true,
    ReadDescription: true,
    NeedPay: true,
    custom1: false,
    custom2: false,
  };

  private showTermOfServicesBlock = true;
  private formDisabled = true;

  private AddressTitleOptions: { text: string; value: string | null }[] = [];

  get formDisable() {
    if (
      (this.getProperty &&
        this.getProperty.Goal == 1 &&
        this.getConfig.showConfirmReadDescription === "no" &&
        this.getConfig.showConfirmEnoughMoney === "no" &&
        this.getConfig.showConfirmMaxPerson === "no" &&
        this.getConfig.showConfirmNeedPay === "no" &&
        this.getConfig.showConfirmRequestProof === "no") ||
      (this.getProperty &&
        this.getProperty.Goal == 0 &&
        this.getConfig.showConfirmReadDescriptionForSale === "no")
    ) {
      this.showTermOfServicesBlock = false;
      this.formDisabled = false;
    }
    return this.formDisabled;
  }

  get formLoading() {
    return this.getStatusSendFormLoading;
  }

  get formStatus() {
    return this.getStatusSendForm;
  }

  get formRoofs() {
    return this.form.roofs;
  }

  set formRoofs(val) {
    this.form.roofs = val;
    this.checkRoofs();
  }

  get appointment() {
    return this.getAppointmentByID(this.appointmentId) || null;
  }

  get appointmentTime() {
    const appointmentByID = this.getAppointmentByID(this.appointmentId);
    if (typeof appointmentByID !== "undefined" && appointmentByID) {
      const appointment = deepClone(appointmentByID);
      const startTime = new Date(appointment.StartTime);
      const endTime = new Date(appointment.EndTime);
      appointment.ddStart =
        (startTime.getDate() < 10 ? "0" : "") + startTime.getDate();
      appointment.mmStart =
        (startTime.getMonth() + 1 < 10 ? "0" : "") + (startTime.getMonth() + 1);
      appointment.yyyyStart = startTime.getFullYear();
      appointment.hoursStart =
        (startTime.getHours() < 10 ? "0" : "") + startTime.getHours();
      appointment.minStart =
        (startTime.getMinutes() < 10 ? "0" : "") + startTime.getMinutes();
      appointment.hoursEnd =
        (endTime.getHours() < 10 ? "0" : "") + endTime.getHours();
      appointment.minEnd =
        (endTime.getMinutes() < 10 ? "0" : "") + endTime.getMinutes();
      const time = `${appointment.ddStart}/${appointment.mmStart}/${
        appointment.yyyyStart
      } 
      ${this.$t("FROM")} ${appointment.hoursStart}:${appointment.minStart} 
      ${this.$t("TO")} ${appointment.hoursEnd}:${appointment.minEnd}`;
      return time;
    }
    return "";
  }

  get confirmEnoughMoney() {
    if (
      this.getConfig.confirmEnoughMoney &&
      this.getConfig.confirmEnoughMoney !== "undefined" &&
      this.getProperty.MinimumRevenue
    ) {
      const str = this.getConfig.confirmEnoughMoney as string;
      return str.replace("{0}", this.getProperty.MinimumRevenue.toString());
    }
    if (this.getProperty && this.getProperty.MinimumRevenue) {
      const str = this.$t("APPOINTMENT_CONFIRM_ENOUGH_MONEY") as string;
      return str.replace(
        "{0}",
        parseFloat(this.getProperty.MinimumRevenue.toString()).toFixed(2)
      );
    }
    return "";
  }

  get showConfirmEnoughMoney() {
    if (this.property && this.property.Goal === 0) {
      // not show with property for sale
      this.roofsCheck.EnoughMoney = false;
      return false;
    }
    if (
      this.getConfig.showConfirmEnoughMoney &&
      this.getConfig.showConfirmEnoughMoney === "no"
    ) {
      this.roofsCheck.EnoughMoney = false;
      return false;
    }
    if (
      this.getProperty &&
      this.getProperty.MinimumRevenue &&
      this.getProperty.MinimumRevenue > 0
    ) {
      this.roofsCheck.EnoughMoney = true;
      return true;
    }
    this.roofsCheck.EnoughMoney = false;
    return false;
  }

  get confirmRequestProof() {
    if (this.showConfirmRequestProof) {
      if (
        this.getConfig.confirmRequestProof &&
        this.getConfig.confirmRequestProof !== "undefined"
      ) {
        const str = this.getConfig.confirmRequestProof as string;
        return str;
      }
      const str = this.$t("APPOINTMENT_CONFIRM_REQUEST_PROOF") as string;
      return str;
    }
    return "";
  }

  get showConfirmRequestProof() {
    if (this.property && this.property.Goal === 0) {
      // not show with property for sale
      return false;
    }
    if (
      this.getConfig.showConfirmRequestProof &&
      this.getConfig.showConfirmRequestProof === "no"
    ) {
      return false;
    }
    return true;
  }

  get confirmMaxPerson() {
    if (
      this.getConfig.confirmMaxPerson &&
      this.getConfig.confirmMaxPerson !== "undefined" &&
      this.getProperty.MaxNrPersons
    ) {
      const str = this.getConfig.confirmMaxPerson as string;
      return str.replace("{0}", this.getProperty.MaxNrPersons.toString());
    }
    if (this.getProperty && this.getProperty.MaxNrPersons) {
      const str = this.$t("APPOINTMENT_CONFIRM_MAX_PERSON") as string;
      return str.replace("{0}", this.getProperty.MaxNrPersons.toString());
    }
    return "";
  }

  get showConfirmMaxPerson() {
    if (this.property && this.property.Goal === 0) {
      // not show with property for sale
      this.roofsCheck.MaxPerson = false;
      return false;
    }
    if (
      this.getConfig.showConfirmMaxPerson &&
      this.getConfig.showConfirmMaxPerson === "no"
    ) {
      this.roofsCheck.MaxPerson = false;
      return false;
    }
    if (
      this.getProperty &&
      this.getProperty.MaxNrPersons &&
      this.getProperty.MaxNrPersons > 0
    ) {
      this.roofsCheck.MaxPerson = true;
      return true;
    }
    this.roofsCheck.MaxPerson = false;
    return false;
  }

  get confirmReadDescription() {
    if (
      this.getConfig.confirmReadDescription &&
      this.getConfig.confirmReadDescription !== "undefined"
    ) {
      return this.getConfig.confirmReadDescription;
    }
    if (this.getProperty) {
      const str = this.$t("APPOINTMENT_CONFIRM_READ_DESCRIPTION") as string;
      return str;
    }
    return "";
  }

  get showConfirmReadDescription() {
    if (
      this.getConfig.showConfirmReadDescription &&
      this.getConfig.showConfirmReadDescription === "no"
    ) {
      this.roofsCheck.ReadDescription = false;
      return false;
    }
    if (this.getProperty) {
      this.roofsCheck.ReadDescription = true;
      return true;
    }
    this.roofsCheck.ReadDescription = false;
    return false;
  }

  get confirmReadDescriptionForSale() {
    if (
      this.getConfig.confirmReadDescriptionForSale &&
      this.getConfig.confirmReadDescriptionForSale !== "undefined"
    ) {
      return this.getConfig.confirmReadDescriptionForSale;
    }
    if (this.getProperty) {
      const str = this.$t(
        "APPOINTMENT_CONFIRM_READ_DESCRIPTION_FOR_SALE"
      ) as string;
      return str;
    }
    return "";
  }

  get showConfirmReadDescriptionForSale() {
    if (
      this.getConfig.showConfirmReadDescriptionForSale &&
      this.getConfig.showConfirmReadDescriptionForSale === "no"
    ) {
      this.roofsCheck.ReadDescription = false;
      return false;
    }
    if (this.getProperty) {
      this.roofsCheck.ReadDescription = true;
      return true;
    }
    this.roofsCheck.ReadDescription = false;
    return false;
  }

  get confirmNeedPay() {
    if (this.getProperty) {
      const data = this.getProperty;
      const regionOfProperty = getRegionType(Number(data.Zip));
      let monthPay =
        regionOfProperty === "Brussels" || regionOfProperty === "Wallonia"
          ? 2
          : 3;
      let rentGuarantee = 0;
      let total = 0;
      let text = "";

      if (
        this.getConfig.confirmNeedPayTotal &&
        this.getConfig.confirmNeedPayTotal !== "undefined"
      ) {
        text = this.getConfig.confirmNeedPayTotal as string;
      } else {
        text = this.$t("APPOINTMENT_CONFIRM_NEED_PAY_TOTAL") as string;
      }

      if (data.Price && data.Price > 0) {
        rentGuarantee = parseFloat(data.Price.toString()) * monthPay;
        total = total + parseFloat(data.Price.toString()) + rentGuarantee;
        if (
          this.getConfig.confirmNeedPayPrice &&
          this.getConfig.confirmNeedPayPrice !== "undefined"
        ) {
          text =
            text +
            "\n\n" +
            (this.getConfig.confirmNeedPayPrice as string).replace(
              "{0}",
              parseFloat(data.Price.toString()).toFixed(2)
            );
        } else {
          text =
            text +
            "\n\n" +
            (this.$t("APPOINTMENT_CONFIRM_NEED_PAY_PRICE") as string).replace(
              "{0}",
              parseFloat(data.Price.toString()).toFixed(2)
            );
        }
      }

      if (data.ChargesRenter && data.ChargesRenter > 0) {
        total = total + parseFloat(data.ChargesRenter.toString());
        if (
          this.getConfig.confirmNeedPayChargesRenter &&
          this.getConfig.confirmNeedPayChargesRenter !== "undefined"
        ) {
          text =
            text +
            "\n" +
            (this.getConfig.confirmNeedPayChargesRenter as string).replace(
              "{0}",
              parseFloat(data.ChargesRenter.toString()).toFixed(2)
            );
        } else {
          text =
            text +
            "\n" +
            (
              this.$t("APPOINTMENT_CONFIRM_NEED_PAY_CHARGESRENTER") as string
            ).replace(
              "{0}",
              parseFloat(data.ChargesRenter.toString()).toFixed(2)
            );
        }
      }

      if (data.RenterTotalCostVatInc && data.RenterTotalCostVatInc > 0) {
        total = total + parseFloat(data.RenterTotalCostVatInc.toString());
        if (
          this.getConfig.confirmNeedPayTotalCost &&
          this.getConfig.confirmNeedPayTotalCost !== "undefined"
        ) {
          text =
            text +
            "\n" +
            (this.getConfig.confirmNeedPayTotalCost as string).replace(
              "{0}",
              parseFloat(data.RenterTotalCostVatInc.toString()).toFixed(2)
            );
        } else {
          text =
            text +
            "\n" +
            (
              this.$t("APPONTMENT_CONFIRM_NEED_PAY_RENTERTOTALCOST") as string
            ).replace(
              "{0}",
              parseFloat(data.RenterTotalCostVatInc.toString()).toFixed(2)
            );
        }
      }

      if (data.Price && data.Price > 0) {
        if (
          this.getConfig.confirmNeedPayPriceX3 &&
          this.getConfig.confirmNeedPayPriceX3 !== "undefined"
        ) {
          text =
            text +
            "\n" +
            (this.getConfig.confirmNeedPayPriceX3 as string)
              .replace("{1}", monthPay.toString())
              .replace("{0}", parseFloat(rentGuarantee.toString()).toFixed(2));
        } else {
          text =
            text +
            "\n" +
            (this.$t("APPONTMENT_CONFIRM_NEED_PAY_PRICEX3") as string)
              .replace("{1}", monthPay.toString())
              .replace("{0}", parseFloat(rentGuarantee.toString()).toFixed(2));
        }
      }

      text = text.replace("{0}", parseFloat(total.toString()).toFixed(2));

      return text;
    }
    return "";
  }

  get showConfirmNeedPay() {
    if (this.property && this.property.Goal === 0) {
      // not show with property for sale
      this.roofsCheck.NeedPay = false;
      return false;
    }
    if (
      this.getConfig.showConfirmNeedPay &&
      this.getConfig.showConfirmNeedPay === "no"
    ) {
      this.roofsCheck.NeedPay = false;
      return false;
    }
    if (
      this.getProperty &&
      this.getProperty.Price &&
      this.getProperty.Price > 0
    ) {
      this.roofsCheck.NeedPay = true;
      return true;
    }
    this.roofsCheck.NeedPay = false;
    return false;
  }

  get conditionCustom1() {
    if (
      this.getConfig.conditionCustom1 &&
      this.getConfig.conditionCustom1 !== ""
    ) {
      this.roofsCheck.custom1 = true;
      return this.getConfig.conditionCustom1 as string;
    } else {
      this.roofsCheck.custom1 = false;
      return "";
    }
  }

  get conditionCustom2() {
    if (
      this.getConfig.conditionCustom2 &&
      this.getConfig.conditionCustom2 !== ""
    ) {
      this.roofsCheck.custom2 = true;
      return this.getConfig.conditionCustom2 as string;
    } else {
      this.roofsCheck.custom2 = false;
      return "";
    }
  }

  get isMobileView() {
    if (this.isMobile && this.isMobile === "true") {
      return true;
    }
    return false;
  }

  get getConfirmTimeAppointment() {
    if (this.appointment) {
      let startDate = moment(this.appointment.StartTime).locale(
        this.$i18n.locale
      );
      let endDate = moment(this.appointment.EndTime).locale(this.$i18n.locale);
      return `${startDate.format("HH:mm")}, ${startDate.format(
        "dddd D MMMM YYYY"
      )}`;
    }
  }

  get getDurationTimeAppointment() {
    if (this.appointment) {
      let startDate = moment(this.appointment.StartTime).locale(
        this.$i18n.locale
      );
      let endDate = moment(this.appointment.EndTime).locale(this.$i18n.locale);
      let duration = moment.duration(endDate.diff(startDate)).asMinutes();
      return duration + " min";
    }
  }

  get showWhenFull() {
    if (
      (typeof this.appointment === "undefined" || this.appointment === null) &&
      this.getConfig.showWhenFull &&
      this.getConfig.showWhenFull == "yes"
    ) {
      return true;
    }
    return false;
  }

  get showWhenFullTitle() {
    if (this.getConfig.showWhenFullTitle != "") {
      return this.getConfig.showWhenFullTitle;
    }
    return "";
  }

  get showWhenFullDescription() {
    if (this.getConfig.showWhenFullDescription != "") {
      return this.getConfig.showWhenFullDescription;
    }
    return "";
  }

  get showWhenNoAppointments() {
    if (
      (typeof this.appointment === "undefined" || this.appointment === null) &&
      this.getConfig.showWhenNoAppointments &&
      this.getConfig.showWhenNoAppointments == "yes"
    ) {
      return true;
    }
    return false;
  }

  get emailSend() {
    let email = this.getConfig.email;
    if (this.getConfig.emailTest && this.getConfig.emailTest !== "") {
      email = this.getConfig.emailTest;
    }
    if (
      this.appointment &&
      this.appointment.UserEmail &&
      this.appointment.UserEmail !== ""
    ) {
      email = this.appointment.UserEmail;
    }
    return email;
  }

  get observer() {
    if (this.$refs.observer) {
    }
    return "";
  }

  public async mounted() {
    this.subject_mail = this.$t("CONTACT_APPOINTMENT") as string;
    this.AddressTitleOptions = [
      { text: this.$t("MR") as string, value: "MR" },
      { text: this.$t("MS") as string, value: "MS" },
    ];
    this.form.AddressTitle = "MR" as string;
    this.secretKey = this.getConfig.key as string;
    this.objectId = this.getConfig.objectId as string;
    this.email = this.getConfig.email as string;
    this.logo = this.getConfig.logo as string;
    this.isMobile = this.getConfig.isMobile as string;
    this.appointmentId = this.getAppointmentClicked.toString();
    this.handleSendHeight();
    sendMessage({ type: "focus-modal", mess: "" });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  handleSendHeight() {
    if (this.getConfig.isMobile === "false") {
      const appEl = document.getElementById("app");
      if (appEl) sendMessage({ type: "resize-modal", mess: "default" });
    }
  }

  getValidationState({ dirty, validated, valid = null }: any) {
    return dirty || validated ? valid : null;
  }

  async onSubmit() {
    try {
      // check if appointment exists
      let validate = await this.checkAppointmentExists(
        this.getAppointmentClicked
      );
      if (
        (this.getConfig.showWhenFull &&
          this.getConfig.showWhenFull === "yes") ||
        (this.getConfig.showWhenNoAppointments &&
          this.getConfig.showWhenNoAppointments == "yes")
      ) {
        validate = true;
      }
      if (!validate) {
        this.$alert(this.$t("APPOINTMENT_TAKEN") as string, "", {
          confirmButtonText: "OK",
          callback: (action) => {
            this.$router.replace({
              name: "open-appointment",
            });
          },
        });
      }

      // check if user already applied for appointment
      let alreadyRegistered = await this.checkIfUserRegistered({
        email: this.form.Email,
      });
      if (alreadyRegistered) {
        this.$alert(this.$t("ALLREADY_SUBSCRIBED") as string, "", {
          confirmButtonText: "OK",
          callback: (action) => {
            this.$router.replace({
              name: "open-appointment",
            });
          },
        });
      }

      if (validate && !alreadyRegistered) {
        await this.sendContactAppointment({
          objectId: this.objectId,
          key: this.secretKey,
          email: this.emailSend,
          data: this.prepareDataToSend(),
          noEmail: this.getConfig.sendConfirmation === "yes" ? false : true,
        });
        this.$router.replace({
          name: "confirm-appointment",
          query: {
            isSuccess: "1",
            propertyAddress: this.propertyAddress,
            appointmentDateTime: this.appointment
              ? moment(this.appointment.StartTime)
                  .locale(this.$i18n.locale)
                  .format("dddd, MMM D, YYYY HH:mm")
              : "",
          },
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public checkRoofs() {
    let error = 0;
    for (const confirm in this.roofsCheck) {
      if (this.roofsCheck.hasOwnProperty(confirm)) {
        // @ts-ignore
        const value: boolean = this.roofsCheck[confirm] as boolean;
        if (value) {
          const findCheck = this.form.roofs.filter(function (obj) {
            return obj === confirm;
          });
          const inputCheck = document.getElementById(`input-group-${confirm}`);
          if (inputCheck && findCheck.length === 0) {
            inputCheck.children[0].classList.add("warning");
            setTimeout(function () {
              if (inputCheck) {
                inputCheck.children[0].classList.remove("warning");
              }
            }, 1000);
            error++;
          }
        }
      }
    }
    if (error === 0) {
      this.showTermOfServices = false;
      this.formDisabled = false;
    } else {
      this.showTermOfServices = true;
      this.formDisabled = true;
    }
  }

  public prepareDataToSend() {
    const languageId =
      LANGUAGE_CONST.find((lang) => lang.name === this.getConfig.language)!
        .id || "nl";
    const letterTitle = this.$t("DEAR_" + this.form.AddressTitle) as string;
    const addressTitle = this.$t(this.form.AddressTitle) as string;
    const propertyUrl = this.getConfig.propertyurl || "";
    const data = {
      ContactData: this.removeParamsNULL({
        Name: this.form.Name,
        FirstName: this.form.FirstName,
        LetterTitle: letterTitle,
        AddressTitle: addressTitle,
        Email: this.form.Email,
        PhoneNumber: this.form.PhoneNumber,
        // Content: this.form.Content,
        Subject: this.subject_mail,
        IsReceivedEmail: this.form.IsReceivedEmail,
        ObjectID: this.objectId,
        AppointmentID: this.appointmentId,
        LanguageId: languageId,
        EventType: this.appointment ? "" : "waitinglist",
        PropertyLink: propertyUrl,
      }),
      headers: {
        ReCaptchaResponse: "",
        UseCaptcha: false,
      },
      SendMail: {
        HtmlBody: this.compileMailToString().innerHTML,
      },
    };
    return data;
  }

  public compileMailToString() {
    return new ContactAppointmentMail({
      i18n: this.$i18n,
      propsData: {
        FIELD_SITE_LINK: this.getConfig.siteUrl || "",
        LOGO: this.getConfig.logo,
        FIELD_SUBJECT: "Appointment Online",
        FIELD_FIRSTNAME: this.form.FirstName,
        FIELD_NAME: this.form.Name,
        FIELD_PHONENUMBER: this.form.PhoneNumber,
        FIELD_EMAIL: this.form.Email,
        // FIELD_CONTENT: this.form.Content,

        FIELD_OBJECTID: this.property.ID,
        FIELD_PROPERTY_APPOINTMENT_TEXT: this.appointmentTime,
        FIELD_PROPERTY_GOAL: this.property.Goal,
        FIELD_PROPERTY_STREET: this.property.Street,
        FIELD_PROPERTY_HOUSENUMBER: this.property.HouseNumber,
        FIELD_PROPERTY_ZIP: this.property.Zip,
        FIELD_PROPERTY_CITY: this.property.City,
        FIELD_PROPERTY_TYPE: this.property.WebIDName,
        FIELD_PROPERTY_LINK: this.getConfig.propertyUrl || "",

        FIELD_SITE_ADDRESS_TITLE: this.form.AddressTitle,
        FIELD_SITE_STREET_HOUSENUMBER: "",
        FIELD_SITE_CITY_ZIP: "",
        FIELD_SITE_PHONE: "",
        FIELD_SITE_EMAIL: "",
        buttonBackColor: this.buttonBackColor,
        font: this.font,
      },
    }).$mount().$el;
  }

  private removeParamsNULL(params: any) {
    const newParams: any = { ...params };
    for (const propName in newParams) {
      if (
        newParams[propName] === null ||
        newParams[propName] === undefined ||
        newParams[propName] === "" ||
        newParams[propName] === "none"
      ) {
        delete newParams[propName];
      }
    }
    return newParams;
  }
}
