export const LANGUAGE_ZONE = {
    nl: ""
}

export const DOMAIN_CONTACT_APPOINTMENT = "appointment-online-v2.omnicasaweb.com";

export const LANGUAGE_CONST = [
    {
        name: 'nl',
        id: 1
    },
    {
        name: 'fr',
        id: 2
    },
    {
        name: 'en',
        id: 3
    }
];

export const ADDRESS_TITLE_CONST = [
    {
        name: 'Mr',
        mapping: 'Mr.'
    },
    {
        name: 'Mrs',
        mapping: 'Mrs'
    },
    {
        name: 'en',
        id: 3
    }
]